import { ApplicationCapabilityDefinition } from "classes/models/capabilities/application-capability-definition.model";
import { CapabilitiesService } from "classes/models/capabilities/capabilities.service";
import { UserCounts } from "classes/models/userCounts/user-counts.model";
import { UserCountsService } from "classes/models/userCounts/user-counts.service";
import { Subscription } from "rxjs";
import { UserCapabilities } from "~/classes/models/capabilities/user-capabilities.model";
import { UserPackage } from "~/classes/models/capabilities/user-package.model";
import { ConsumablesService } from "~/classes/models/consumables/consumables.service";
import { UserConsumables } from "~/classes/models/consumables/user-consumable.model";
import { OrganizationSummary } from "~/classes/models/organizations/organization-summary.model";
import { OrganizationsService } from "~/classes/models/organizations/organizations.service";

export const useCapabilitiesService = (userId: string) =>
  definePiniaStore(`/capabilities/service/${userId}`, () => {
    const userCapabilities = ref<UserCapabilities | undefined>();
    const userCounts = ref<UserCounts | undefined>();
    const userPackages = ref<UserPackage[] | undefined>();
    const userConsumables = ref<UserConsumables | undefined>();
    const applicationCapabilitiesDefinition = ref<
      ApplicationCapabilityDefinition | undefined
    >();
    const organizationSummary = ref<OrganizationSummary | undefined>();

    const applicationDefinitionSubscription = ref<Subscription | undefined>();
    const organizationSummarySubscription = ref<Subscription | undefined>();
    const userCountsSubscription = ref<Subscription | undefined>();
    const userConsumablesSubscription = ref<Subscription | undefined>();

    const userClassroomsData = useUserClassroomsDataStore();
    const { classrooms } = storeToRefs(userClassroomsData);

    const isInitialized = ref(false);

    const initialize = async () => {
      if (isInitialized.value) return;

      console.log("Init user capabilities");

      await initUserCapabilities();

      console.log("Init user counts");
      await initializeUserCounts();

      console.log("Init user consumables");
      await initUserConsumabled();

      isInitialized.value = true;
    };

    watch(
      [
        userCounts,
        userPackages,
        userConsumables,
        classrooms,
        organizationSummary,
      ],
      () => {
        if (userCapabilities.value == undefined) return;

        userCapabilities.value = new UserCapabilities({
          userId: userId,
          applicationCapabilitiesDefinition:
            applicationCapabilitiesDefinition.value ??
            userCapabilities.value!.applicationCapabilitiesDefinition,
          userPackageVersion: userCapabilities.value!.userPackageVersion,
          userPackages:
            userPackages.value ?? userCapabilities.value!.userPackages,
          userConsumables:
            userCapabilities.value!.userConsumables ?? userConsumables.value,
          userCounts: userCounts.value ?? userCapabilities.value!.userCounts,
          userClassrooms:
            classrooms.value ?? userCapabilities.value!.userClassrooms,
          organizationSummary:
            organizationSummary.value ??
            userCapabilities.value!.organizationSummary,
        });
      }
    );

    const initUserCapabilities = async () => {
      console.log("Init user capabilities - here");

      userCapabilities.value = await UserCapabilities.initialize(userId);

      if (userCapabilities.value.userPackageVersion.organizationId) {
        console.log("Init organization summary");
        initOrganizationSummary(
          userCapabilities.value.userPackageVersion.organizationId
        );
      }

      applicationDefinitionSubscription.value?.unsubscribe();

      console.log("Init application definition");
      applicationDefinitionSubscription.value =
        CapabilitiesService.streamApplicationDefinitionAndActiveUserPackages(
          userId
        ).subscribe((data) => {
          applicationCapabilitiesDefinition.value = data.applicationDefinition;
          userPackages.value = data.userPackages;
        });
    };


    const initOrganizationSummary = async (organizationId: string) => {
      organizationSummarySubscription.value?.unsubscribe();

      organizationSummarySubscription.value =
        OrganizationsService.streamOrganizationSummary({
          organizationId,
        }).subscribe((data) => {
          organizationSummary.value = data;
        });
    };

    const initializeUserCounts = async () => {
      if (process.client) {
        userCountsSubscription.value?.unsubscribe();
        userCountsSubscription.value = UserCountsService.streamUserCounts(
          userId
        ).subscribe((data) => {
          userCounts.value = data;
        });
      }
    };

    const initUserConsumabled = () => {
      if (process.client) {
        userConsumablesSubscription.value?.unsubscribe();
        userConsumablesSubscription.value =
          ConsumablesService.streamUserConsumables(userId).subscribe((data) => {
            userConsumables.value = data;
          });
      }
    };

    const appUserDataStore = useAppUserData();
    const { isUserAdmin, isUserSystemAdmin } = storeToRefs(appUserDataStore);

    const canGradeDocument = computed(() => {
      return (documentId: string) => {
        // Basic checks
        if (isUserAdmin.value || isUserSystemAdmin.value) return true;

        return userCapabilities.value?.canGradeDocument(documentId) ?? true;
      };
    });

    const numAllowedClassrooms = computed(() => {
      if (userCapabilities.value == undefined) return 0;

      return userCapabilities.value.numAllowedClassrooms;
    });

    const numActiveClassrooms = computed(() => {
      if (userCapabilities.value == undefined) return 0;

      return userCapabilities.value.numActiveClassrooms;
    });

    const hasEnoughClassroomLicenses = computed(() => {
      if (isUserAdmin.value || isUserSystemAdmin.value) return true;

      if (userCapabilities.value == undefined) return true;

      return userCapabilities.value.hasEnoughClassroomLicenses;
    });

    const canUserAddClassroom = computed(() => {
      if (isUserAdmin.value || isUserSystemAdmin.value) return true;

      if (userCapabilities.value == undefined) return true;

      return userCapabilities.value.canUserAddClassroom;
    });

    const hasSubscription = computed(() => {
      if (isUserAdmin.value || isUserSystemAdmin.value) return true;

      if (userCapabilities.value == undefined) return false;

      return userCapabilities.value.hasSubscription;
    });

    return {
      isInitialized,
      initialize,
      userCapabilities,
      canGradeDocument,
      hasEnoughClassroomLicenses,
      canUserAddClassroom,
      numActiveClassrooms,
      numAllowedClassrooms,
      hasSubscription,
    };
  });
